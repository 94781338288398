import {request} from "../service/request"
let url = process.env.VUE_APP_BASE_URL // 接口地址

// 动态url
function dynamicUrl(url) {
  if (url) {
    let exportUrl = '';
    Object.keys(url).map((key)=>{
      exportUrl += key + '=' + url[key] +'&';
    })
    exportUrl = exportUrl.substring(exportUrl.length-1,0)
    return exportUrl;
  }
}



// S 新增   08-16
// -用户列表 表格数据-
export function userAdminData(data) {
  var paraUrl = dynamicUrl(data)
  return request({
    url: url + '/v1/admin/user/index?'+ paraUrl,
    method: 'get',
  })
}


// -登录记录-
export function loginRecord(data) {
  var paraUrl = dynamicUrl(data)
  return request({
    url: url + '/v1/admin/info/index?'+ paraUrl,
    method: 'get',
  })
}

// -工资管理-
export function wagesAdmin(data) {
  var paraUrl = dynamicUrl(data)
  return request({
    url: url + '/v1/admin/wages/index?'+ paraUrl,
    method: 'get',
  })
}

// -用户列表下拉-
export function userOption() {
  return request({
    url: url + '/v1/admin/user/data',
    method: 'get',
  })
}

// -消息发送记录-
export function sendRecord(data) {
  var paraUrl = dynamicUrl(data)
  return request({
    url: url + '/v1/admin/send/index?' + paraUrl,
    method: 'get',
  })
}

// E 新增   08-16
// S 新增前台   08-17
// -工资条列表-
export function phoneList(data) {
  var paraUrl = dynamicUrl(data)
  return request({
    url: url + '/v1/index/index/index?' + paraUrl,
    method: 'get',
  })
}
// -移动端详情-
export function indexInfo(data) {
  var paraUrl = dynamicUrl(data)
  return request({
    url: url + '/v1/index/index/info?' + paraUrl,
    method: 'get',
  })
}

// -移动端确认工资条-
export function confirmWages(data) {
  return request({
    url: url + '/v1/index/index/confirm',
    method: 'post',
    data: data
  })
}

// -管理端工资详情-
export function adminWages(data) {
  var paraUrl = dynamicUrl(data)
  return request({
    url: url + '/v1/admin/wages/info?' + paraUrl,
    method: 'get',
  })
}


// -用户列表 修改接口-
export function userAdminSave(data) {
  return request({
    url: url + '/v1/admin/user/save',
    method: 'post',
    data: data
  })
}

/* ---- 首页部分 ---- */
// 获取部门数据
export function departmentList() {
  return request({
    url: url + '/v1/admin/department/list',
    method: 'get',
  })
}

// 下载模板
export function downloadFile() {
  return request({
    url: url + '/v1/admin/api/download',
    method: 'get',
  })
}

// 下发个人工资信息
export function pushMessage(data) {
  return request({
    url: url + '/v1/admin/push/message',
    method: 'post',
    data: data
  })
}

// 注销登录
export function logOut() {
  return request({
    url: url + '/v1/admin/login/out',
    method: 'get',
  })
}

// 企业微信js-sdk /v1/index/api/ticket
export function getTicket(data) {
  return request({
    url: url + '/v1/index/api/ticket',
    method: 'post',
    data: data
  })
}

export function getUserIdByMobile(data) {
  var paraUrl = dynamicUrl(data)
  return request({
    url: url + '/v1/admin/api/getUserIdByMobile?' + paraUrl,
    method: 'get',
  })
}
// E 新增   08-16
export function getSetUserIds(data) {
  return request({
    url: url + '/v1/admin/api/getSetUserId',
    method: 'post',
    data: data
  })
}