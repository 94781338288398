import { render, staticRenderFns } from "./phoneList.vue?vue&type=template&id=3c1bb865&scoped=true&style=width%3A%20100%25%3Bheight%3A%20100%25%3B&"
import script from "./phoneList.vue?vue&type=script&lang=js&"
export * from "./phoneList.vue?vue&type=script&lang=js&"
import style0 from "./phoneList.vue?vue&type=style&index=0&id=3c1bb865&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "3c1bb865",
  null
  
)

export default component.exports