export function isInterceptorCode(code) {
  switch (code) {
    case 406: // 暂定
    case 403: //非法账户
    case 101: //非法账户
      return true;
    default:
      break;
  }
  return false;
}

