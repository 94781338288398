import Vue from 'vue'
import VueRouter from 'vue-router'

// S 添加重复路由报错问题
// const originalPush = VueRouter.prototype.push
// VueRouter.prototype.push = function push(location) {
//   return originalPush.call(this, location).catch(err => err)
// }
// E 添加重复路由报错问题

Vue.use(VueRouter)
import Home from '../components/home.vue' // home页
import Index from '../components/index.vue' // 首页
import Login from '../components/newLogin.vue' // 登陆页面

import UserAdmin from '../components/userAdmin.vue' // 用户列表
import LoginRecord from '../components/loginRecord.vue' // 登录记录
import WagesAdmin from '../components/wagesAdmin.vue' // 工资管理
import MessageSendRecord from '../components/messageSendRecord.vue' // 消息发送记录

import PhoneList from '../components/phoneList.vue' // 工资条列表
import PhoneIndex from '../components/phoneIndex.vue' // 工资条详情
import PhoneLogin from '../components/phoneLogin.vue' // 移动端授权登录
const routes = [
  //前端登陆拦截页面
  {
    path: '/newLogin',
    name: 'newLogin',
    component: Login
  },
  // 工资条移动端列表phoneList
  {
    path: '/phoneList',
    name: 'phoneList',
    component: PhoneList
  },

  // 工资条详情phoneIndex
  {
    path: '/phoneIndex',
    name: 'phoneIndex',
    component: PhoneIndex
  },

  // 移动端授权登录页
  {
    path: '/phoneLogin',
    name: 'phoneLogin',
    component: PhoneLogin
  },

  {
    path: '/',
    name: 'home',
    component: Home,
    children: [
      // 首页index
      {
        path: '/index',
        name: 'index',
        component: Index
      },

      // 用户列表
      {
        path: '/userAdmin',
        name: 'userAdmin',
        component: UserAdmin
      },
      // 登录记录
      {
        path: '/loginRecord',
        name: 'loginRecord',
        component: LoginRecord
      },
      // 工资管理wagesAdmin
      {
        path: '/wagesAdmin',
        name: 'wagesAdmin',
        component: WagesAdmin
      },
      // 消息发送记录messageSendRecord
      {
        path: '/messageSendRecord',
        name: 'messageSendRecord',
        component: MessageSendRecord
      },
    ]
  }

]

const router = new VueRouter({
  routes
})

export default router
