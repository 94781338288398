<template>
  <div>
    <el-breadcrumb separator="/">
      <el-breadcrumb-item>工资管理</el-breadcrumb-item>
    </el-breadcrumb>
    <div class="main-body">
      <el-form :label-position="labelPosition" class="search-box">
        <el-form-item label="用户：">
          <el-select v-model="user" clearable filterable placeholder="请选择用户" size="small">
            <el-option
                v-for="item in user_arr"
                :key="item.id"
                :label="item.name"
                :value="item.id">
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="时间：">
          <el-date-picker
              style="width: 180px;"
              v-model="time_value"
              value-format="yyyy-MM"
              type="month"
              size="small"
              :picker-options="pickerOptions"
              placeholder="选择日期">
          </el-date-picker>
        </el-form-item>
        <el-button type="primary" icon="el-icon-search" size="small" style="margin: 5px 0;" :loading="searchStates"
                   @click="search">搜索
        </el-button>
      </el-form>
      <el-table
          :data="tableData"
          border
          :stripe="true"
          style="width: 100%">
        <el-table-column
            prop="id"
            label="序号"
            width="80"
            align="left"
        >
        </el-table-column>

        <el-table-column
            prop="name"
            label="姓名">
        </el-table-column>

        <el-table-column
            label="日期">
          <template v-slot="scope">
            <span>{{ scope.row.year }}-{{ scope.row.month }}</span>
          </template>
        </el-table-column>
        <el-table-column
            prop="status"
            label="确认状态">
          <template v-slot="scope">
            <span v-if="scope.row.status === 0">未确认</span>
            <span v-if="scope.row.status === 1">已确认</span>
          </template>
        </el-table-column>
        <el-table-column
            prop="create_time"
            label="创建时间">
        </el-table-column>
        <el-table-column
            width="80"
            label="操作">
          <template v-slot="scope">
            <el-button type="primary" size="mini" @click="wagesDetail(scope.row.id)">详情</el-button>
          </template>
        </el-table-column>
      </el-table>
      <div class="block">
        <el-pagination
            @size-change="handleSizeChange"
            @current-change="handleCurrentChange"
            :current-page="currentPage"
            :page-sizes="[10, 50, 100, 300]"
            :page-size="20"
            layout="total, sizes, prev, pager, next, jumper"
            :total="total">
        </el-pagination>
      </div>
    </div>

    <!-- S 工资详情弹窗 -->
    <div class="dialog-father">
      <el-dialog
          title="工资详情"
          :visible.sync="dialogVisible"
          width="30%"
          :before-close="handleClose">
        <div class="wages-box">
          <div class="wages-item" v-for="(item,index) in wages_data">
            <div class="wages-item-title">{{ index }}：</div>
            <div class="wages-item-info">{{ item }}</div>
          </div>
        </div>
        <span slot="footer" class="dialog-footer">
        <el-button @click="dialogVisible = false">关 闭</el-button>
      </span>
      </el-dialog>
    </div>
    <!-- E 工资详情弹窗 -->
  </div>
</template>

<script>
import {
  wagesAdmin, // 工资管理列表
  userOption, // 用户下拉数据
  adminWages, // 工资详情
} from '@/api/index.js'
import moment from "moment";
import {isInterceptorCode} from "../common/commonFuncs"

export default {
  name: "index",
  data() {
    return {
      labelPosition: 'right', // 搜索条件部分对齐方式
      searchStates: false, // 搜索加载
      total: 0,// 数据总条数
      currentPage: 1, // 当前第几页
      limit: '10', // 每页多少条
      tableData: [], // 表格数据
      user: '', // 用户回显
      user_arr: [], // 用户数据
      time_value: '', // 时间回显
      pickerOptions: {
        disabledDate(time) {
          return time.getTime() > Date.now();
        },
      },
      dialogVisible: false, // 工资详情弹窗
      wages_data: [] // 工资详情集合
    }
  },
  created() {
    this.getTableData() // 获取表格数据
    this.getUserOption() // 获取用户下拉数据
  },
  methods: {
    // 时间戳转化(年-月-日 时:分:秒)
    formatDate(time) {
      return moment(time * 1000).format("YYYY-MM-DD hh:mm:ss")
    },
    // 打开详情弹窗
    wagesDetail(id) {
      let para = {id: id}
      adminWages(para).then(data => {
        if (data.code === 200) {
          this.wages_data = data.data
        } else {
          if (!isInterceptorCode(data.code)) {
            this.$message.error(data.message)
          }
        }
      }).catch(() => {
        this.$message.error('服务器连接失败!')
      })
      this.dialogVisible = true
    },

    // 关闭详情弹窗
    handleClose() {
      this.dialogVisible = false
    },

    // 用户下拉数
    getUserOption() {
      userOption().then(data => {
        if (data.code === 200) {
          this.user_arr = data.data // 下拉赋值
        } else {
          if (!isInterceptorCode(data.code)) {
            this.$message.error(data.message)
          }
        }
      }).catch(() => {
        this.$message.error('服务器连接失败!')
      })
    },

    // 获取数据
    getTableData(state) {
      // 清除时间时重新赋值为空字符串否则接口报错
      if (this.time_value === null) {
        this.time_value = ''
      }

      let para = {
        page: this.currentPage, // 页码
        limit: this.limit, // 每页显示条数
        user_id: this.user, // 用户
        time: this.time_value // 时间
      }
      wagesAdmin(para).then(data => {
        if (data.code === 200) {
          this.tableData = data.data.data // 表格赋值
          this.total = data.data.count // 总条数
          if (state) {
            this.$message.success('搜索成功')
            this.searchStates = false // 关闭加载按钮
          }
        } else {
          if (!isInterceptorCode(data.code)) {
            this.$message.error(data.message)
          }
        }
      }).catch(() => {
        this.$message.error('服务器连接失败!')
      })
    },

    // 搜索
    search() {
      let state = true // 点击搜索时提示搜索成功
      this.searchStates = true // 开启加载按钮
      this.currentPage = 1 // 重置页码
      this.getTableData(state)
    },

    // 每页多少条
    handleSizeChange(val) {
      this.limit = val
      this.getTableData()
    },

    // 当前第几页
    handleCurrentChange(val) {
      this.currentPage = val
      this.getTableData()
    }
  }
}
</script>

<style scoped>
.search-box {
  display: flex;
  flex-wrap: wrap;
  margin-bottom: 22px;
}

.search-box .el-form-item {
  display: flex;
  margin: 0 15px 0 0;
}

.search-box .el-form-item .el-select {
  width: 180px;
}

/* ---- 工资详情弹窗 ---- */
.wages-item {
  display: flex;
  height: 30px;
  line-height: 30px;
}

.dialog-father >>> .el-dialog__body {
  padding: 10px 20px;
}

.wages-item-title {
  width: 115px;
  margin-right: 5px;
  color: #A1A7BA;
  font-size: 16px;
  text-align: right;
}

.wages-item-info {
  color: #414141;
  font-size: 16px;
}
</style>