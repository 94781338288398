<template style="width: 100%;height: 100%;">
  <div style="height: 100vh">
    <div class="list-body">
      <div class="search-term">
        <div class="time-left">当前年度</div>
        <div class="time-slot" @click="selectShow" style="width:65px;display:flex;position: relative">
          <van-field class="vant-input-edit demo" v-model="select_value" readonly placeholder="请选择"/>
          <div class="down-icon time-clear">
            <van-icon name="arrow" color="#414141"/>
          </div>
        </div>
      </div>
      <!-- S 列表部分 -->
      <div v-if="loading" class="list-wrap">
        <van-loading size="24px">加载中...</van-loading>
      </div>
      <div class="list-wrap" v-for="item in list_data" @click="jumpIndex(item)" v-else>
        <div class="wrap-top">
          <span> {{item.create_time}}</span>
        </div>
        <div class="wrap-bottom">
          <div class="bottom-text">{{item.year}}年{{item.month}}月工资条</div>
          <div class="bottom-num">{{item.net_salary}}</div>
        </div>
      </div>
      <div style="height: 20px"></div>
      <div class="empty-data" v-if="empty_data">暂无更多数据</div>
      <!-- E 列表部分 -->
      <!-- S 时间下拉弹层 -->
      <van-popup v-model:show="select_show" position="bottom" :style="{ height: '48%' }">
        <van-picker
            title="请选择"
            show-toolbar
            :columns="select_data"
            @confirm="onConfirm"
            @cancel="onCancel"
        />
      </van-popup>
      <!-- E 时间下拉弹层 -->
    </div>
  </div>
</template>

<script>
  import {phoneList, getTicket} from '@/api/index.js'
  import {Notify, Dialog} from "vant";
  const wx = window.wx
  export default {
    data() {
      return {
        loading: true,
        empty_data: false, // 暂无数据
        list_data: [], // 列表数据
        select_show: false, // 跟单顾问弹出层
        select_value: '', // 下拉数据
        select_data: [], //下拉数据
      }
    },
    created() {
      window.scrollTo(0, 0) // 进入页面使得滚动条始终位于页面最上方
      this.getSelect() // 获取下拉
      let para = {
        url: window.location.href.split('#')[0]
      }
      getTicket(para).then(data => {
        wx.config({
          beta: true,// 必须这么写，否则wx.invoke调用形式的jsapi会有问题
          debug: false, // 开启调试模式,调用的所有api的返回值会在客户端alert出来，若要查看传入的参数，可以在pc端打开，参数信息会通过log打出，仅在pc端时才会打印。
          appId: process.env.VUE_APP_APPID, // 必填，企业微信的corpID
          timestamp: data.data.timestamp, // 必填，生成签名的时间戳
          nonceStr: data.data.noncestr, // 必填，生成签名的随机串
          signature: data.data.signature,// 必填，签名，见 附录-JS-SDK使用权限签名算法
          jsApiList: ['hideAllNonBaseMenuItem'] // 必填，需要使用的JS接口列表，凡是要调用的接口都需要传进来
        });
        wx.ready(function () {
          wx.hideAllNonBaseMenuItem();
        });
        wx.error(function (res) {
        });
      })
    },
    methods: {
      // 当前年度弹层控制
      selectShow() {
        this.select_show = true
      },

      // 确认赋值
      onConfirm(value, index) {
        this.select_value = value
        this.select_show = false // 关闭弹层
        this.getList()
      },

      // 取消选择
      onCancel() {
        this.select_show = false // 关闭弹层
      },

      // 获取列表数据
      getList() {
        let para = {
          year: this.select_value
        }
        phoneList(para).then(data => {
          if (data.code === 200) {
            this.loading = true;
            // 判断数据为空
            if (data.data.data.length === 0) {
              this.empty_data = true
              this.loading = false; // 停止加载
            } else {
              this.loading = false; // 停止加载
              this.empty_data = false
              this.list_data = data.data.data
            }
          } else {
            Dialog.alert({
              message: '用户身份已过期，点击确定重新登录。',
            }).then(() => {
              this.$router.push({
                path: '/phoneLogin'
              })
            });
          }
        }).catch(() => {
          Notify({type: 'danger', message: '服务器连接失败!'});
        })
      },
      // 获取下拉
      getSelect() {
        let para = {
          year: this.select_value
        }
        phoneList(para).then(data => {
          if (data.code === 200) {
            for (let i = 0; i < data.data.year_data.length; i++) {
              this.select_data.push(data.data.year_data[i].year)
            }
            this.select_value = this.select_data[0]
            this.getList() // 列表数据
          } else {
            Dialog.alert({
              message: '用户身份已过期，点击确定重新登录。',
            }).then(() => {
              this.$router.push({
                path: '/phoneLogin'
              })
            });
          }
        }).catch(() => {
          Notify({type: 'danger', message: '服务器连接失败!'});
        })
      },
      // 跳转详情页
      jumpIndex(item) {
        this.$router.push({
          path: '/phoneIndex',
          query: {
            id: item.id
          }
        })
      },
    },

    /* ---- 改变当前页面body背景颜色 ---- */
    beforeCreate() {
      document.querySelector('body').setAttribute('style', 'background-color:#F5F5FC')
    },

    beforeDestroy() {
      document.querySelector('body').removeAttribute('style')
    },
  }
</script>

<style scoped>
  .header {
    width: 100%;
    height: 50px;
    display: flex;
    justify-content: center;
    align-items: center;
    background: #FFFFFF;
    position: fixed;
    top: 0;
    z-index: 99;
  }

  .header span {
    font-size: 19px;
    color: #222222;
  }

  .list-body {
    height: 100vh;
    background: #F5F5FC;
    padding-top: 10px;
  }

  /* ---- 当前年度 ---- */
  .search-term {
    width: 92%;
    margin: 0 auto;
    background: #ffffff;
    display: flex;
    box-shadow: 0 3px 10px 1px rgba(108, 157, 209, 0.1600);
    border-radius: 10px 10px 10px 10px;
    overflow: hidden;
    align-items: center;
    justify-content: space-between;
  }

  .time-left {
    margin-left: 15px;
    font-size: 15px;
    color: #414141;
  }

  .demo {
    font-size: 15px;
    color: #414141;
    padding: 0;
    height: 45px;
    line-height: 47px;
  }

  .down-icon {
    position: absolute;
    top: 15px;
    right: 13px;
    width: 13px;
    color: #606266;
  }

  .list-wrap {
    width: 305px;
    padding: 20px;
    margin: 10px auto 0;
    background: #ffffff;
    box-shadow: 0 3px 20px 1px rgba(108, 157, 209, 0.16);
    border-radius: 20px 20px 20px 20px;
  }

  .wrap-top {
    padding-bottom: 12px;
    border-bottom: 1px solid #DDE0E7;
  }

  .wrap-top span {
    font-size: 15px;
    color: #A1A7BA;
  }

  .wrap-bottom {
    margin-top: 26px;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  .bottom-text {
    font-size: 15px;
    color: #414141;
  }

  .bottom-num {
    font-size: 17px;
    font-weight: bold;
    color: #0E86FF;
  }

  /*暂无数据*/
  .empty-data {
    width: 345px;
    margin: 15px auto;
    color: #A1A7BA;
    font-size: 15px;
    text-align: center;
  }
</style>