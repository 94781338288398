<template>
  <div id="app">
    <router-view/>
  </div>
</template>

<script>


export default {

}
</script>

<style>
  body {
    margin: 0;
    padding: 0;
  }
  #app {
    margin: 0;
    padding: 0;
  }
  .el-pagination {
    margin-top: 15px;
  }
  .el-main{
    background:#F5F5FC ;
    padding: 0 !important;
  }
  .main-body{
    margin-top: 20px;
    padding: 20px;
    background: #FFFFFF;
  }
  .el-breadcrumb {
    height: 50px !important;
    padding-left: 20px !important;
    margin-bottom: 20px !important;
    background: #fff !important;
    line-height: 50px !important;
  }


  .el-radio-button:first-child .el-radio-button__inner {
    border: none;
  }

  .el-radio-button__orig-radio:checked+.el-radio-button__inner {
    box-shadow: none;
  }

  .el-radio-button__inner,
  .el-radio-button__orig-radio:checked+.el-radio-button__inner {
    background: unset !important;
    color: #fff !important;
    border: none !important;
  }
</style>
