<template>
  <div class="pages-box">
    <!-- S 头部蓝色部分 -->
    <div class="head-blue">
      <div class="wages-time">{{ wages_data.year }}年{{ wages_data.month }}月实发</div>
      <div class="wages-num">{{ wages_data.net_salary }}</div>
      <div class="wages-bottom">
        <div class="wages-item">
          <div class="wages-item-title">应缴个税</div>
          <div class="wages-item-info">{{ wages_data.income_tax_payable_month }}</div>
        </div>
        <div class="wages-item">
          <div class="wages-item-title">保险公积金</div>
          <div class="wages-item-info">{{ wages_data.insurance_accumulation_fund }}</div>
        </div>
        <div class="wages-item">
          <div class="wages-item-title">出勤扣款</div>
          <div class="wages-item-info">{{ wages_data.deduction_for_attendance }}</div>
        </div>
        <div class="wages-item">
          <div class="wages-item-title">其他扣款</div>
          <div class="wages-item-info">{{ wages_data.other_deductions }}</div>
        </div>
      </div>
    </div>
    <!-- E 头部蓝色部分 -->
    <!-- S 基本信息部分 -->
    <div class="body-info">
      <div class="body-title">基本信息</div>
      <div class="wages-detail">
        <div class="wages-detail-item">
          <div class="detail-item-title">序号</div>
          <div class="detail-item-info">{{ wages_data.id }}</div>
        </div>
        <div class="wages-detail-item">
          <div class="detail-item-title">所属公司</div>
          <div class="detail-item-info">{{ user_data.company }}</div>
        </div>
        <div class="wages-detail-item">
          <div class="detail-item-title">姓名</div>
          <div class="detail-item-info">{{ user_data.name }}</div>
        </div>
        <div class="wages-detail-item">
          <div class="detail-item-title">部门</div>
          <div class="detail-item-info">{{ user_data.department }}</div>
        </div>
        <div class="wages-detail-item">
          <div class="detail-item-title">入职日期</div>
          <div class="detail-item-info" v-if="user_data.date_of_entry">{{ formatDate(user_data.date_of_entry) }}</div>
          <div class="detail-item-info" v-if="!user_data.date_of_entry">暂无数据</div>
        </div>
      </div>
    </div>
    <!-- E 基本信息部分 -->
    <!-- S 出勤情况 -->
    <div class="body-info">
      <div class="body-title">出勤情况</div>
      <div class="wages-detail">
        <div class="wages-detail-item">
          <div class="detail-item-title">应出勤天数</div>
          <div class="detail-item-info">{{ wages_data.days_of_attendance }}</div>
        </div>
        <div class="wages-detail-item">
          <div class="detail-item-title">实出勤天数</div>
          <div class="detail-item-info">{{ wages_data.actual_attendance_days }}</div>
        </div>
      </div>
    </div>
    <!-- E 出勤情况 -->
    <!-- S 工资明细 -->
    <div class="body-info">
      <div class="body-title">工资明细</div>
      <div class="wages-detail">
        <div class="wages-detail-item">
          <div class="detail-item-title">基本工资</div>
          <div class="detail-item-info">{{ wages_data.base_pay }}</div>
        </div>
        <div class="wages-detail-item">
          <div class="detail-item-title">岗位津贴</div>
          <div class="detail-item-info">{{ wages_data.post_allowance }}</div>
        </div>
        <div class="wages-detail-item">
          <div class="detail-item-title">电脑补贴</div>
          <div class="detail-item-info">{{ wages_data.computer_subsidy }}</div>
        </div>
        <div class="wages-detail-item">
          <div class="detail-item-title">提成</div>
          <div class="detail-item-info">{{ wages_data.commission }}</div>
        </div>
        <div class="wages-detail-item">
          <div class="detail-item-title">出勤扣款</div>
          <div class="detail-item-info">{{ wages_data.deduction_for_attendance }}</div>
        </div>
        <div class="wages-detail-item">
          <div class="detail-item-title">社保</div>
          <div class="detail-item-info">{{ wages_data.social_security }}</div>
        </div>
        <div class="wages-detail-item">
          <div class="detail-item-title">公积金</div>
          <div class="detail-item-info">{{ wages_data.accumulation_fund }}</div>
        </div>
        <div class="wages-detail-item">
          <div class="detail-item-title">本月应缴个税</div>
          <div class="detail-item-info">{{ wages_data.income_tax_payable_month }}</div>
        </div>
        <div class="wages-detail-item">
          <div class="detail-item-title">周末工资</div>
          <div class="detail-item-info">{{ wages_data.weekend_salary }}</div>
        </div>
        <div class="wages-detail-item">
          <div class="detail-item-title">绩效工资</div>
          <div class="detail-item-info">{{ wages_data.merit_pay }}</div>
        </div>
        <div class="wages-detail-item">
          <div class="detail-item-title">其他扣款</div>
          <div class="detail-item-info">{{ wages_data.other_deductions }}</div>
        </div>
        <div class="wages-detail-item">
          <div class="detail-item-title">其他</div>
          <div class="detail-item-info">{{ wages_data.other }}</div>
        </div>
        <div class="wages-detail-item">
          <div class="detail-item-title">备注</div>
          <div class="detail-item-info">{{ wages_data.remark }}</div>
        </div>
      </div>
    </div>
    <!-- E 工资明细 -->
    <!-- S 底部合计 -->
    <div class="bottom-box">
      <div class="bottom-box-l">
        <div class="actual-wages">实发工资</div>
        <div class="actual-wages-info">{{ wages_data.net_salary }}</div>
      </div>
      <div class="confirm-btn" v-if="wages_confirm" @click="confirmWages">确认工资条</div>
      <div class="confirmed-btn" v-if="!wages_confirm">工资条已确认</div>
    </div>
    <!-- E 底部合计 -->
  </div>
</template>

<script>
import {
  indexInfo, // 工资详情
  confirmWages, // 确认工资条
  getTicket // 企业微信sdk
} from '@/api/index.js';
import {Dialog, Toast, Notify} from "vant";
import moment from "moment";

export default {
  data() {
    return {
      wages_confirm: true, // 确认工资条
      user_data: {}, // 用户数据
      wages_data: {}, // 工资数据
      wages_id: '' // 工资详情id
    }
  },
  created() {
    if (!this.$route.query.id) {
      Toast.fail('非法进入');
      return
    }
    this.wages_id = this.$route.query.id // 详情id
    this.getWagesDetail();
    let para = {
      url: window.location.href.split('#')[0]
    }
    getTicket(para).then(data => {
      wx.config({
        beta: true,// 必须这么写，否则wx.invoke调用形式的jsapi会有问题
        debug: false, // 开启调试模式,调用的所有api的返回值会在客户端alert出来，若要查看传入的参数，可以在pc端打开，参数信息会通过log打出，仅在pc端时才会打印。
        appId: process.env.VUE_APP_APPID, // 必填，企业微信的corpID
        timestamp: data.data.timestamp, // 必填，生成签名的时间戳
        nonceStr: data.data.noncestr, // 必填，生成签名的随机串
        signature: data.data.signature,// 必填，签名，见 附录-JS-SDK使用权限签名算法
        jsApiList: ['hideAllNonBaseMenuItem'] // 必填，需要使用的JS接口列表，凡是要调用的接口都需要传进来
      });
      wx.ready(function () {
        wx.hideAllNonBaseMenuItem();
      });
      wx.error(function (res) {
        console.log(res)
      });
    })
  },
  methods: {
    // 时间戳转化(年-月-日)
    formatDate(time) {
      return moment(time * 1000).format("YYYY-MM-DD")
    },

    // 获取详情数据
    getWagesDetail() {
      let para = {
        id: this.wages_id
      }
      indexInfo(para).then(data => {
        if (data.code === 200) {
          this.user_data = data.data.user // 用户数据集合
          this.wages_data = data.data.data // 工资数据集合
          this.wages_confirm = this.wages_data.status !== 1;
        } else {
          Toast.fail(data.message);
        }
      }).catch(() => {
        Notify({type: 'danger', message: '服务器链接失败'});
      })
    },

    // 确认工资条
    confirmWages() {
      Dialog.confirm({
        message: '是否确认工资条',
      }).then(() => {
        let para = {
          id: this.wages_data.id
        }
        confirmWages(para).then(data => {
          if (data.code === 200) {
            this.wages_confirm = false
            Toast.success('操作成功');
          } else {
            Toast.fail(data.message);
          }
        }).catch(() => {
          Notify({type: 'danger', message: '服务器链接失败'});
        })
      }).catch(() => {
        // on cancel
      });
    }
  }
}
</script>

<style scoped>
/* ---- 背景颜色 ---- */
.pages-box {
  position: absolute;
  top: 0;
  height: auto;
  background: #F5F5FC;
}

.head-title img {
  position: absolute;
  top: 18px;
  left: 15px;
  width: 15px;
}

/* ---- 头部蓝色部分 ---- */
.head-blue {
  position: relative;
  width: 375px;
  height: 205px;
  background: #0E86FF;
  overflow: hidden;
}

.wages-time {
  margin-top: 28px;
  color: #DCEDFF;
  font-size: 14px;
  text-align: center;
}

.wages-num {
  margin-top: 15px;
  color: #fff;
  font-size: 35px;
  font-weight: bold;
  text-align: center;
}

.wages-bottom {
  position: absolute;
  bottom: 0;
  display: flex;
  justify-content: space-between;
  width: 325px;
  height: 68px;
  padding: 0 25px;
  background: #1A8CFF;
}

.wages-item {
  width: 56px;
  height: 68px;
}

.wages-item:nth-of-type(2) {
  width: 70px;
}

.wages-item-title {
  margin: 11px 0 5px;
  color: #DCEDFF;
  font-size: 14px;
  text-align: center;
  white-space: nowrap;
}

.wages-item-info {
  color: #fff;
  font-size: 14px;
  text-align: center;
}

/* ---- 基本信息部分 ---- */
.body-title {
  height: 45px;
  padding-left: 15px;
  color: #414141;
  font-size: 15px;
  line-height: 45px;
}

.wages-detail {
  background: #fff;
}

.wages-detail-item {
  display: flex;
  justify-content: space-between;
  height: 44px;
  padding: 0 15px;
  border-bottom: 1px dashed #CCCFD9;
}

.wages-detail-item:last-child {
  height: 45px;
  border: none;
}

.detail-item-title {
  color: #A1A7BA;
  font-size: 15px;
  line-height: 45px;
}

.detail-item-info {
  color: #414141;
  font-size: 15px;
  text-align: right;
  line-height: 45px;
}

/* ---- 底部合计 ---- */
.bottom-box {
  display: flex;
  justify-content: space-between;
  height: 103px;
  margin-top: 15px;
  padding: 0 15px;
  background: #fff;
  box-shadow: 0 -5px 5px 1px rgba(57, 78, 103, 0.05);
}

.bottom-box-l {
  width: 200px;
}

.actual-wages {
  margin: 16px 0 5px;
  color: #A1A7BA;
  font-size: 15px;
}

.actual-wages-info {
  color: #0D82FF;
  font-size: 25px;
  font-weight: bold;
}

.confirm-btn {
  width: 125px;
  height: 50px;
  margin-top: 20px;
  background: #0E86FF;
  color: #fff;
  font-size: 15px;
  text-align: center;
  line-height: 50px;
  border-radius: 10px;
  box-shadow: 0 3px 4px 1px rgba(14, 134, 255, 0.3);
}

.confirmed-btn {
  width: 125px;
  height: 50px;
  margin-top: 20px;
  background: #DEE0E6;
  color: #8D919E;
  font-size: 15px;
  text-align: center;
  line-height: 50px;
  border-radius: 10px;
  box-shadow: 0 3px 4px 1px rgba(222, 224, 230, 0.3);
}
</style>