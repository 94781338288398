<template>
  <div>
    <el-breadcrumb separator="/">
      <el-breadcrumb-item>消息记录</el-breadcrumb-item>
    </el-breadcrumb>
    <div class="main-body">
      <el-form :label-position="labelPosition" class="search-box">
        <el-form-item label="用户：">
          <el-select v-model="user" clearable filterable placeholder="请选择用户" size="small">
            <el-option
                v-for="item in user_arr"
                :key="item.id"
                :label="item.name"
                :value="item.id">
            </el-option>
          </el-select>
        </el-form-item>
        <el-button type="primary" icon="el-icon-search" size="small" style="margin: 5px 0;" :loading="searchStates"
                   @click="search">搜索
        </el-button>
      </el-form>
      <el-table
          :data="tableData"
          border
          :stripe="true"
          style="width: 100%">
        <el-table-column
            prop="id"
            label="序号"
            width="80"
            align="left"
        >
        </el-table-column>
        <el-table-column
            width="300"
            prop="name"
            label="姓名">
        </el-table-column>
        <el-table-column
            width="200"
            prop="create_time"
            label="创建时间">
        </el-table-column>
        <el-table-column
            prop="content"
            label="内容">
        </el-table-column>
      </el-table>
      <div class="block">
        <el-pagination
            @size-change="handleSizeChange"
            @current-change="handleCurrentChange"
            :current-page="currentPage"
            :page-sizes="[10, 50, 100, 300]"
            :page-size="20"
            layout="total, sizes, prev, pager, next, jumper"
            :total="total">
        </el-pagination>
      </div>
    </div>
  </div>
</template>

<script>
  import {
    sendRecord, // 消息列表
    userOption, // 用户下拉数据
  } from '@/api/index.js'
  import moment from "moment";
  import {isInterceptorCode} from "../common/commonFuncs"

  export default {
    name: "index",
    data() {
      return {
        labelPosition: 'right', // 搜索条件部分对齐方式
        searchStates: false, // 搜索加载
        total: 0,// 数据总条数
        currentPage: 1, // 当前第几页
        limit: '10', // 每页多少条
        tableData: [], // 表格数据
        user: '', // 用户回显
        user_arr: [], // 用户数据
      }
    },
    created() {
      this.getTableData() // 获取表格数据
      this.getUserOption() // 获取用户下拉数据
    },

    methods: {
      // 时间戳转化(年-月-日 时:分:秒)
      formatDate(time) {
        return moment(time * 1000).format("YYYY-MM-DD hh:mm:ss")
      },

      // 用户下拉数
      getUserOption() {
        userOption().then(data => {
          if (data.code === 200) {
            this.user_arr = data.data // 下拉赋值
          } else {
            if (!isInterceptorCode(data.code)) {
              this.$message.error(data.message)
            }
          }
        }).catch(() => {
          this.$message.error('服务器连接失败!')
        })
      },

      // 获取数据
      getTableData(state) {
        let para = {
          page: this.currentPage, // 页码
          limit: this.limit, // 每页显示条数
          user_id: this.user, // 用户
        }
        sendRecord(para).then(data => {
          if (data.code === 200) {
            this.tableData = data.data.data // 表格数据赋值
            this.total = data.data.count // 总条数
            if (state) {
              this.$message.success('搜索成功')
              this.searchStates = false // 关闭加载按钮
            }
          } else {
            if (!isInterceptorCode(data.code)) {
              this.$message.error(data.message)
            }
          }
        }).catch(() => {
          this.$message.error('服务器连接失败!')
        })
      },

      // 搜索
      search() {
        let state = true // 点击搜索时提示搜索成功
        this.searchStates = true // 开启加载按钮
        this.currentPage = 1 // 重置页码
        this.getTableData(state)
      },

      // 每页多少条
      handleSizeChange(val) {
        this.limit = val
        this.getTableData()
      },

      // 当前第几页
      handleCurrentChange(val) {
        this.currentPage = val
        this.getTableData()
      }
    }
  }
</script>

<style scoped>
  .search-box {
    display: flex;
    flex-wrap: wrap;
    margin-bottom: 22px;
  }

  .search-box .el-form-item {
    display: flex;
    margin: 0 15px 0 0;
  }

  .search-box .el-form-item .el-select {
    width: 180px;
  }
</style>
