<template>
  <div>
    <el-breadcrumb separator="/">
      <el-breadcrumb-item>登录记录</el-breadcrumb-item>
    </el-breadcrumb>
    <div class="main-body">
      <div style="display: flex; height: 42px; margin-bottom: 22px">
        <el-form rel="form" class="search-box" style="width: 100%">
        </el-form>
      </div>
      <el-table
          :data="tableData"
          border
          :stripe="true"
          style="width: 100%">
        <el-table-column
            prop="id"
            label="序号"
            width="80"
            align="left"
        >
        </el-table-column>
        <el-table-column
            prop="create_time"
            label="登录时间">
        </el-table-column>
        <el-table-column
            prop="name"
            label="登录人">
        </el-table-column>
      </el-table>
      <div class="block">
        <el-pagination
            @size-change="handleSizeChange"
            @current-change="handleCurrentChange"
            :current-page="currentPage"
            :page-sizes="[10, 50, 100, 300]"
            :page-size="20"
            layout="total, sizes, prev, pager, next, jumper"
            :total="total">
        </el-pagination>
      </div>
    </div>
  </div>
</template>
<script>
  import {loginRecord} from '@/api'
  import {isInterceptorCode} from "../common/commonFuncs"

  export default {
    data() {
      return {
        total: 0,// 数据总条数
        currentPage: 1, // 当前第几页
        limit: '10', // 每页多少条
        tableData: [], // 表格数据
      }
    },

    created() {
      this.getList()
    },

    methods: {
      // 获取列表数据
      getList() {
        let para = {
          page: this.currentPage, // 页码
          limit: this.limit // 每页多少条
        }
        loginRecord(para).then(data => {
          if (data.code === 200) {
            this.tableData = data.data.data
            this.total = data.data.count
          } else {
            if (!isInterceptorCode(data.code)) {
              this.$message.error(data.message)
            }
          }
        }).catch(() => {
          this.$message.error('服务器连接失败!')
        })
      },

      // 每页多少条
      handleSizeChange(val) {
        this.limit = val;
        this.getList()
      },

      // 当前第几页
      handleCurrentChange(val) {
        this.currentPage = val;
        this.getList()
      },
    },
  }
</script>
<style scoped>
  .search-box {
    display: flex;
    flex-wrap: wrap;
    margin-bottom: 22px;
  }

  .search-box .el-form-item {
    display: flex;
    margin: 0 15px 0 0;
  }

  .search-box .el-form-item .el-select {
    width: 180px;
  }
</style>
