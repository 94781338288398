<template>
  <div>
    <el-breadcrumb separator="/">
      <el-breadcrumb-item>首页</el-breadcrumb-item>
    </el-breadcrumb>
    <div class="main-body">
      <div style="display: flex; margin-bottom: 22px">
        <el-steps :active="active" finish-status="success" style="width: 65%">
          <el-step title="下载工资模板"
                   description="请点击下方“下载工资模板”按钮，下载模板，并填充工资数据。"></el-step>
          <el-step title="上传解析数据"
                   description="请点击下方“上传”按钮，选择填充数据后的表格文件，并核对数据。"></el-step>
          <el-step title="一键下发通知" description="请选择“工资发放时间”后，点击“一键下发”。"></el-step>
        </el-steps>
      </div>
      <el-button style="margin-top: 12px;" @click="prev" :disabled="next_button_disabled">上一步</el-button>
      <el-button style="margin-top: 12px;" @click="next" :disabled="prev_button_disabled">下一步</el-button>
      <div v-if="active === 0" style="margin-top: 15px">
        <a class="template-download" :href="template_url">下载工资模板</a>
      </div>
      <div v-if="active === 1" style="margin-top: 45px">
        <el-upload
            :multiple="false"
            style="margin: 30px 0 10px"
            :headers="headers"
            class="upload-demo"
            :action="uploadUrl"
            :on-error="fileError"
            :on-success="uploadAddSuccess"
            :before-remove="beforeRemove"
            :on-remove="removeEnclosure"
            :file-list="fileList"
            name="file"
        >
          <el-button size="mini" icon="el-icon-upload" type="primary">上传</el-button>
        </el-upload>
        <el-table
            :data="tableWagesList"
            border
            :stripe="true"
            style="width: 100%">
          <el-table-column v-for="(item,index) in title_data"
                           :label="item"
                           width="80"
                           align="left"
          >
            <template slot-scope="scope">{{ scope.row[index] }}</template>
          </el-table-column>
        </el-table>
      </div>
      <div v-if="active === 2" style="margin-top: 45px">
        <div style="display: flex; margin-bottom: 10px">
          <div style="font-size: 14px; color: #606266; line-height: 32px">工资发放时间：</div>
          <div>
            <el-date-picker
                style="width: 180px;"
                v-model="time_value"
                value-format="yyyy-MM"
                type="month"
                size="small"
                :picker-options="pickerOptions"
                placeholder="选择日期">
            </el-date-picker>
          </div>
          <el-button style="margin-left: 10px" type="primary" size="mini" @click="issueWagesInfo(arr_key)">
            一键下发通知
          </el-button>
        </div>
        <el-table
            :data="tableWagesList"
            border
            :stripe="true"
            style="width: 100%">
          <el-table-column
              label="发送状态"
              width="80"
              align="left"
          >
            <template v-slot="scope">
              <span v-if="scope.row[0] === '0'">未发送</span>
              <span style="color: #67C23A" v-if="scope.row[0] === '1'">已发送</span>
              <span style="color: #F56C6C;" v-if="scope.row[0] === '2'">发送失败</span>
            </template>
          </el-table-column>
          <el-table-column v-for="(item,index) in title_data" v-if="index!==0"
                           :label="item"
                           width="80"
                           align="left"
          >
            <template slot-scope="scope">
              {{ scope.row[index] }}
            </template>
          </el-table-column>
        </el-table>
      </div>
    </div>
  </div>
</template>
<script>
import {
  getSetUserIds, getUserIdByMobile, // 下载模板
  pushMessage // 下发个人工资信息
} from '@/api/index.js'
import moment from "moment";
import {isInterceptorCode} from "../common/commonFuncs"

export default {
  name: "index",
  data() {
    return {
      active: 0, // 步骤条选中
      tableData: [], // 部门数据表格
      headers: {
        Authorization: '',
      },   //上传请求头
      fileList: [], // 上传数组
      enclosure_url: [], // 文件路径
      uploadUrl: process.env.VUE_APP_BASE_URL + '/v1/admin/upload/file', // 文件上传接口
      time_value: '', // 时间回显
      pickerOptions: {
        disabledDate(time) {
          return time.getTime() > Date.now();
        },
      },
      timer: '', // 计时器
      arr_key: 0, // 轮询表格下表
      next_button_disabled: true, // 上一步禁用状态
      prev_button_disabled: false, // 下一步禁用状态
      /* ---- 步骤限制部分 ---- */
      step_one: false, // 第一步开关
      one_loading: false, // 第一步按钮加载
      step_three: false, // 第三步开关
      template_url: '/SalaryTemplate.xls', // 模板下载地址
      title_data: [],
      tableWagesList: [],
    }
  },
  created() {
    this.headers.Authorization = sessionStorage.getItem('token')  // 赋值上传header头
  },
  methods: {
    // 时间戳转化(年-月-日 时:分:秒)
    formatDate(time) {
      return moment(time * 1000).format("YYYY-MM-DD hh:mm:ss")
    },
    // 步骤条选中
    next() {
      var _this = this;
      if (_this.active === 1) {
        if (!_this.step_three) {
          _this.$message.error('请上传文件')
        } else {
          _this.active++
          _this.addZero(_this.tableWagesList, function () {
            _this.$nextTick(function () {
              for (let i = 0; i < _this.tableWagesList.length; i++) {
                _this.$set(_this.tableWagesList[i], 0, '0')
              }
            })
          });
        }
      } else {
        _this.active++
      }
      _this.buttonDisabled();
    },
    buttonDisabled() {
      this.next_button_disabled = false
      this.prev_button_disabled = true
      if (this.active === 0) {
        this.next_button_disabled = true
        this.prev_button_disabled = false
      } else if (this.active === 1) {
        this.next_button_disabled = false
        this.prev_button_disabled = false
      }
    },
    addZero(arr, callback) {
      var _this = this;
      let i = 0;
      arr.forEach(function (subArr) {
        subArr.unshift('0');
        i++;
        if (i === arr.length) {
          _this.title_data.unshift("发送状态");
          callback(arr);
        }
      });
    },
    removeAndSort(arr) {
      return arr.map(innerArr => {
        innerArr.shift(); // 删除第0个元素
        return innerArr; // 返回删除后的数组
      }).sort(); // 排序数组
    },
    prev() {
      if (this.active-- === 1) this.active = 0;
      this.removeAndSort(this.tableWagesList)
      this.title_data.shift();
      this.buttonDisabled();
    },
    // 下发个人工资信息
    issueWagesInfo(index) {
      if (!this.time_value) {
        this.$message.error('请选择时间')
        return
      }
      if (this.arr_key === this.tableWagesList.length) {
        clearTimeout(this.timer)
        this.arr_key = 0
        return
      }
      var _this = this;
      pushMessage({
        data_rows: this.tableWagesList[index],
        title: this.title_data,
        time: this.time_value
      }).then(data => {
        if (data.code !== 200) {
          this.$nextTick(function () {
            _this.$set(this.tableWagesList[index], 0, '2')
          })
        } else {
          this.$nextTick(function () {
            _this.$set(this.tableWagesList[index], 0, '1')
          })
        }
        this.timer = setTimeout(() => {
          this.arr_key += 1
          this.issueWagesInfo(this.arr_key)
        }, 500)
      })
    },
    // 图片上传失败
    fileError() {
      this.$message.error('上传失败')
    },
    findPhoneNumber(arr) {
      const phoneRegex = /^1[3456789]\d{9}$/; // 手机号正则表达式
      for (let i = 0; i < arr.length; i++) {
        const str = arr[i].toString(); // 将数组元素转为字符串
        if (phoneRegex.test(str)) { // 判断是否为手机号
          return str;
        }
      }
      return null; // 找不到手机号返回 null
    },
    // 上传成功
    uploadAddSuccess(res, file) {
      if (res.code === 200) {
        this.$message.success('解析成功!')
        this.step_three = true // 关闭第三步开关限制
        this.tableWagesList = res.data.rows // 表格赋值
        this.title_data = res.data.title // 表格赋值
        this.enclosure_url.push(res.data)  // 附件文件路径
        //根据手机号获取用户id
        this.title_data.push("用户ID");
        for (let i = 0; i < this.tableWagesList.length; i++) {
          getUserIdByMobile({phone: this.findPhoneNumber(this.tableWagesList[i])}).then((re) => {
            let params = {
              phone: this.findPhoneNumber(this.tableWagesList[i]),
              name: this.tableWagesList[i][1],
              user_id: re.data.userid,
              department: this.tableWagesList[i][3],
              company: this.tableWagesList[i][2]
            }
            getSetUserIds(params).then((result) => {
              if (result.code === 200) {
                this.tableWagesList[i].push(result.data)
              } else {
                this.$message.error(result.message)
              }
            }).catch((err) => {

            });
          }).catch((err) => {
            this.tableWagesList[i].push("")
          });
        }
      } else {
        if (!isInterceptorCode(res.code)) {
          this.$message.error(res.message)
        }
      }
    },

    // 删除前 提示(添加)
    beforeRemove(file, fileList) {
      return this.$confirm(`确定移除 ${file.name}？`);
    },

    // 附件删除
    removeEnclosure(file, fileList) {
      this.enclosure_url = [] // 清空在push
      if (fileList.length === 0) {
        this.enclosure_url = ''
      }
      for (let i = 0; i < fileList.length; i++) {
        this.enclosure_url.push(fileList[i].url)
      }
    },
  }
}
</script>

<style scoped>
.template-download {
  display: inline-block;
  width: 104px;
  height: 28px;
  margin: 30px 0 10px;
  background: #409EFF;
  color: #fff;
  font-size: 12px;
  text-align: center;
  line-height: 28px;
  border-radius: 3px;
}

.template-download:hover {
  background: #66b1ff;
}
</style>