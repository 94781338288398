import Vue from 'vue'
import App from './App.vue'
import router from './router'
import './plugins/element.js'
import Vant from 'vant'
import 'vant/lib/index.css'

Vue.use(Vant)
Vue.config.productionTip = false
router.beforeEach((to, from, next) => {
  let token = sessionStorage.getItem('token')  // 后台token
  if (to.path === '/phoneLogin' || to.path === '/phoneList' || to.path === '/phoneIndex') {
    next()
    return
  }

  if (token) {
    // 判断有token时 跳转到前台列表页面 拦截到newLogin页面
      next()
  } else if (to.path === '/newLogin' ) {
    if (to.path === '/newLogin') {
      next()
    } else {
      next('/newLogin')
    }
  }
  else {
    next('/newLogin')
  }
})

new Vue({
  router,
  render: h => h(App)
}).$mount('#app')
