<template>
  <div id="home-container" style="height: 100%;">
    <el-container style="height:100%;">
      <el-header style="display: flex;justify-content: space-between;">
        <div style="display: flex">
          <div v-if="isCollapse" style="width: 64px;" class="logo">工</div>
          <div v-else class="logo" style="width: 145px;">工资条</div>
          <el-radio-group v-model="isCollapse" class="switch-nav" @change="switchNav">
            <el-radio-button :label="false" v-show="isNav == true">
              <i class="el-icon-s-unfold"></i>
            </el-radio-button>
            <el-radio-button :label="true" v-show="isNav == false">
              <i class="el-icon-s-fold"></i>
            </el-radio-button>
          </el-radio-group>
        </div>
        <div class="Log-out">
          <div style="color: #fff;">{{userName}}</div>
        </div>
      </el-header>
      <el-container>
        <el-aside
            style="overflow-y: auto; width: auto"
            class="scroll-bar"
            v-bind:style="{height:mainHeight}">
          <el-menu
              @open="handleOpen" @close="handleClose" :collapse="isCollapse"
              :default-active="$route.path"
              class="el-menu-vertical-demo"
              background-color="#545c64"
              text-color="#fff"
              active-text-color="#ffd04b"
              :router="true">
            <el-menu-item v-for="(item, index) in nav" :index=" '/' + item.path" :key="index">
              <i :class="item.ico"></i>
              <span slot="title">{{item.name}}</span>
            </el-menu-item>
          </el-menu>
        </el-aside>
        <el-main v-bind:style="{height:mainHeight}">
          <router-view/>
        </el-main>
      </el-container>
    </el-container>
  </div>
</template>

<script>
  import {logOut} from '@/api/index.js'
  import {isInterceptorCode} from "@/common/commonFuncs";

  export default {
    data() {
      return {
        isCollapse: false,
        isNav: false,
        visible: false,
        mainHeight: (window.innerHeight - 60) + 'px',
        nav: [
          {path: 'index', name: '首页', ico: 'el-icon-s-home'},
          {path: 'wagesAdmin', name: '工资管理', ico: 'el-icon-s-order'},
          {path: 'userAdmin', name: '用户列表', ico: 'el-icon-s-custom'},
          {path: 'messageSendRecord', name: '消息记录', ico: 'el-icon-s-comment'},
          {path: 'loginRecord', name: '登录记录', ico: 'el-icon-message-solid'},
        ],
        userName: '' // 用户名
      }
    },
    created() {
      this.userName = sessionStorage.getItem('name')
    },
    methods: {
      handleOpen(key, keyPath) {
        console.log(key, keyPath);
      },
      handleClose(key, keyPath) {
        console.log(key, keyPath);
      },
      // 开关监听
      switchNav(val) {
        this.isNav = !!val
      }
    },
    mounted() {
      // 根据窗口高度动态改变左侧导航高度
      window.onresize = () => {
        this.mainHeight = (window.innerHeight - 60) + 'px'
      }
    }
  }
</script>
<style scoped>
  .el-header {
    padding: 0 15px 0 0;
    background: #333742;
  }

  .el-aside {
    display: block;
    position: relative;
    overflow: hidden;
    background-color: #333742 !important;
  }

  .el-menu-item {
    background: #333742 !important;
  }

  .logo {
    height: 60px;
    color: #fff;
    font-size: 18px;
    line-height: 60px;
    text-align: center;
  }

  .el-menu {
    border: none;
  }

  .Log-out {
    display: flex;
    align-items: center;
  }

  .el-menu-item:hover {
    background: #545C64 !important;
  }

  .nav-wrap {
    position: relative;
  }

  /*自定义滚动条*/
  .scroll-bar {
    overflow: auto;
  }

  .scroll-bar::-webkit-scrollbar {
    width: 5px;
    height: 10px;
  }

  /*滑块的样式*/
  .scroll-bar::-webkit-scrollbar-thumb {
    background-color: #C1C1C1;
    border-radius: 10px;
  }

  /*主干部分*/
  .scroll-bar::-webkit-scrollbar-track {
    -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0);
    background-color: white;
  }

  .master {
    position: fixed;
    top: 0;
    left: 150px;
    z-index: 999;
    width: 100%;
    height: 100%;
    background: #000;
    opacity: .5;
  }

  .el-menu-vertical-demo:not(.el-menu--collapse) {
    width: 150px;
    min-height: 50px;
  }

  .switch-nav {
    margin-top: 10px;
  }
</style>
