<template>
  <div>
    <el-breadcrumb separator="/">
      <el-breadcrumb-item>用户列表</el-breadcrumb-item>
    </el-breadcrumb>
    <div class="main-body">
      <el-form :label-position="labelPosition" class="search-box">
        <el-form-item label="姓名：">
          <el-input style="width:180px" v-model="name" autocomplete="off" clearable placeholder="请输入姓名"
                    size="small"></el-input>
        </el-form-item>
        <el-button type="primary" icon="el-icon-search" size="small" style="margin: 5px 0;" :loading="searchStates"
                   @click="search">搜索
        </el-button>
      </el-form>
      <el-table
          :data="tableData"
          border
          :stripe="true"
          style="width: 100%">
        <el-table-column
            prop="id"
            label="序号"
            width="80"
            align="left"
        >
        </el-table-column>
        <el-table-column
            prop="name"
            label="姓名">
        </el-table-column>
        <el-table-column
            prop="mobile"
            label="手机号">
        </el-table-column>
        <el-table-column
            prop="user_id"
            label="用户ID">
        </el-table-column>
        <el-table-column
            prop="department"
            label="部门">
        </el-table-column>
        <el-table-column
            prop="company"
            label="公司">
        </el-table-column>
        <el-table-column
            prop="status"
            width="80"
            label="工资状态">
          <template slot-scope="scope">
            <span v-if="scope.row.status === 0">不开</span>
            <span v-if="scope.row.status === 1">开</span>
          </template>
        </el-table-column>
        <el-table-column
            prop="login_status"
            width="80"
            label="登录权限">
          <template slot-scope="scope">
            <span v-if="scope.row.login_status === 0">禁止</span>
            <span v-if="scope.row.login_status === 1">允许</span>
          </template>
        </el-table-column>
        <el-table-column
            width="180"
            prop="create_time"
            label="创建时间">
        </el-table-column>
        <el-table-column
            width="180"
            prop="date_of_entry"
            label="入职时间">
          <template v-slot="scope">
            <span>{{ formatDate(scope.row.date_of_entry) }}</span>
          </template>
        </el-table-column>
        <el-table-column
            width="100"
            label="操作">
          <template slot-scope="scope">
            <el-button size="mini" type="primary" @click="modifyBtn(scope.row)">修改</el-button>
          </template>
        </el-table-column>
      </el-table>
      <div class="block">
        <el-pagination
            @size-change="handleSizeChange"
            @current-change="handleCurrentChange"
            :current-page="currentPage"
            :page-sizes="[10, 50, 100, 300]"
            :page-size="20"
            layout="total, sizes, prev, pager, next, jumper"
            :total="total">
        </el-pagination>
      </div>
    </div>
    <!-- S 添加弹窗 -->
    <el-dialog
        :title="save_title"
        :close-on-click-modal="false"
        :before-close="addDialogClose"
        width="600px"
        :visible.sync="add_dialog_visible"
    >
      <el-form ref="form" label-width="180px">
        <el-form-item label="姓名：" :required="true">
          <el-input size="small" placeholder="请输入姓名" v-model="add_name">
          </el-input>
        </el-form-item>
        <el-form-item label="登录权限：" :required="true">
          <el-radio-group class="popup-radio" v-model="add_login_status">
            <el-radio :label="0">禁止</el-radio>
            <el-radio :label="1">允许</el-radio>
          </el-radio-group>
        </el-form-item>
        <el-form-item label="工资状态：" :required="true">
          <el-radio-group class="popup-radio" v-model="add_status">
            <el-radio :label="0">不开</el-radio>
            <el-radio :label="1">开</el-radio>
          </el-radio-group>
        </el-form-item>
        <el-form-item label="入职日期：">
          <el-date-picker
              v-model="add_time"
              type="date"
              placeholder="选择日期">
          </el-date-picker>
        </el-form-item>
        <el-form-item label="公司：" :required="true">
          <el-input size="small" placeholder="请输入公司" v-model="add_company">
          </el-input>
        </el-form-item>
        <el-form-item style="text-align: right; margin-top: 20px; margin-bottom: 0">
          <el-button @click="add_dialog_visible = false">取消</el-button>
          <el-button type="primary" @click="confirmSave">确定</el-button>
        </el-form-item>
      </el-form>
    </el-dialog>
    <!-- E 添加弹窗 -->
  </div>
</template>
<script>
import {userAdminData, userAdminSave} from '@/api'
import moment from "moment";
import {isInterceptorCode} from "@/common/commonFuncs"

export default {
  data() {
    return {
      total: 0,// 数据总条数
      currentPage: 1, // 当前第几页
      limit: '10', // 每页多少条
      tableData: [], // 表格数据
      /* ---- 保存弹窗数据 ---- */
      save_title: '',
      add_dialog_visible: false, // 弹窗控制
      labelPosition: 'right', //筛选条件右对齐
      add_login_status: '', // 登录权限
      add_status: '',// 工资状态
      add_time: '',// 入职时间
      add_company: '', // 入职公司
      add_name: '', //姓名
      searchStates: false, // 搜索加载
      name: '',// 用户名
    }
  },

  created() {
    this.getLIst()
  },
  methods: {
    // 时间戳方法
    dateFormat: function (row, column) {
      var date = row[column.property];
      if (date === undefined) {
        return "";
      }
      return moment(date * 1000).format("YYYY-MM-DD HH:mm:ss");
    },

    // 时间戳转化(年-月-日 时:分:秒)
    formatDate(time) {
      if (!time) {
        return ''
      }
      return moment(time * 1000).format("YYYY-MM-DD")
    },

    // 确认保存
    confirmSave() {
      // 修改参数集合
      let modify_para = {
        id: this.modify_id, // 修改id
        login_status: this.add_login_status,
        status: this.add_status,
        date_of_entry: this.add_time,
        company: this.add_company,
        name: this.add_name
      }
      this.saveRequest(modify_para)
    },

    // 点击修改
    modifyBtn(data) {
      this.add_dialog_visible = true // 打开弹窗
      this.save_title = '修改'
      this.modify_id = data.id // 修改id
      this.add_login_status = data.login_status
      this.add_status = data.status
      this.add_company = data.company
      this.add_name = data.name
      if (data.date_of_entry == null || data.date_of_entry === 0 || data.date_of_entry === '') {
        this.add_time = ''
      } else {
        this.add_time = moment(data.date_of_entry * 1000).format("YYYY-MM-DD")
      }
    },

    // 添加修改请求
    saveRequest(para) {
      if (para.name === '') {
        this.$message.error('姓名不能为空，请输入')
        return
      }
      if (para.login_status === '') {
        this.$message.error('登录权限不能为空，请选择')
        return
      }
      if (para.status === '') {
        this.$message.error('状态不能为空，请选择')
        return
      }
      if (para.company === '') {
        this.$message.error('公司不能为空，请输入')
        return
      }
      // 时间转换成 xxxx-xx-xx
      userAdminSave(para).then(data => {
        if (data.code === 200) {
          this.$message.success('保存成功')
          this.add_dialog_visible = false
          this.getLIst() // 更新列表数据
        } else {
          if (!isInterceptorCode(data.code)) {
            this.$message.error(data.message)
          }
        }
      }).catch(() => {
        this.$message.error('服务器连接失败!')
      })
    },
    // 关闭回调
    addDialogClose() {
      this.add_dialog_visible = false // 关闭弹窗
    },

    // 获取列表数据
    getLIst(state) {
      let para = {
        page: this.currentPage, // 页码
        limit: this.limit, // 每页多少条
        name: this.name
      }
      userAdminData(para).then(data => {
        if (data.code === 200) {
          this.tableData = data.data.data // 表格数据赋值
          this.total = data.data.count // 总条数
          if (state) {
            this.$message.success('搜索成功')
            this.searchStates = false // 关闭加载按钮
          }
        } else {
          if (!isInterceptorCode(data.code)) {
            this.$message.error(data.message)
          }
        }
      }).catch(() => {
        this.$message.error('服务器连接失败!')
      })
    },

    // 每页多少条
    handleSizeChange(val) {
      this.limit = val;
      this.getLIst()
    },

    // 当前第几页
    handleCurrentChange(val) {
      this.currentPage = val;
      this.getLIst()
    },

    // 搜索
    search() {
      let state = true // 点击搜索时提示搜索成功
      this.searchStates = true // 开启加载按钮
      this.currentPage = 1 // 重置页码
      this.getLIst(state)
    },
  },
}
</script>
<style scoped>
.search-box {
  display: flex;
  flex-wrap: wrap;
  margin-bottom: 22px;
}

.search-box .el-form-item {
  display: flex;
  margin: 0 15px 0 0;
}

.search-box .el-form-item .el-select {
  width: 180px;
}
</style>
