<template>
  <div></div>
</template>

<script>
  export default {
    created() {
      let login_url = process.env.VUE_APP_BASE_URL + '/v1/index/index/login' // 网页授权接口(正式)
      // 刚进来没有token去登陆,登陆过有token跳过
      if (!this.$route.query.token) {
        location.href = 'https://open.weixin.qq.com/connect/oauth2/authorize?appid=' + process.env.VUE_APP_APPID + '&redirect_uri='+ encodeURIComponent(login_url) +'&response_type=code&scope=snsapi_base&state=STATE#wechat_redirect'
      } else {
        sessionStorage.setItem('token', this.$route.query.token) // 存储token信息
        this.$router.push({
          path: '/phoneList'
        })
      }
    }
  }
</script>

<style scoped>
</style>